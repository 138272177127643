.row-sesion{
    .img-sesion{
        img{
            height: 80px;
        }
    }

    min-height: 70px;
    background-color: #333a54;
    border-bottom: 2px solid #0071FF;
}