.btn {
	padding: 5px 24px;
	border-radius: 4px;
	box-shadow: 0px 3px 9px 0px black;
	cursor: pointer;
	font-weight: 600;
	font-size: 1rem;
	min-width: 150px;
	min-height: 40px;
}

.btn-options {
    padding: 5px 24px;
    border-radius: 4px;
    box-shadow: 0px 3px 9px 0px black;
    font-weight: 600;
    margin-right: 8px;
    height: 30px;
    font-size: 0.93rem;
    text-transform: capitalize;

    cursor: pointer;

    &:last-child {
        margin-right: 0;
    }

    span, i {
        color: $white;
    }
}

select {
  	font-weight: 400 !important;
}

.btn-table-blue {
	color: white;
	width: 27px;
	height: 27px;
	border-radius: 4px;
	transition: all 300ms ease-in-out;
	background-color: #1e85f4af;
	cursor: pointer;
  
  	&:hover {
      	background-color: #1078F2;
	}
}

.btn-table-green {
	color: white;
	width: 27px;
	height: 27px;
	border-radius: 4px;
	transition: all 300ms ease-in-out;
	background-color: rgba(92, 224, 200, 0.685);
	cursor: pointer;
	&:hover {
		background-color: rgb(90, 218, 194);
	}
}

.btn-table-pink {
	color: white;
	width: 27px;
	height: 27px;
	border-radius: 4px;
	transition: all 300ms ease-in-out;
	background-color: rgba(255, 26, 133, 0.541);
	cursor: pointer;
	
	&:hover {
		background-color: #FF1A84;
	}
}

.btn-white {
    background-color: $white;
    span {
        color: $black;
    }
}

.btn-blue {
	background-color: #1078F2;
	&:hover {
		background-color: #0c5ebb;
  }
}

.btn-orange-plus {
    background-color: #FF9607;
    padding: 5px 8px;
    font-size: .8rem;
    color: white;
}


.btn-gray {
    background-color: $gray;
    padding: 5px 8px;
    font-size: .8rem;
    color: white;
}

.btn-orange-cart {
	background-color: #FF9607;
	padding: 5px 8px;
	font-size: .93rem;
	color: white;
	width: 65%;
}


.btn-login {
    width: 80%;
    padding: 10px 16px;
    border-radius: 4px;
    box-shadow: -1px 2px 5px 0px #00000061;
    background-color: #151A26;
    position: relative;
    text-align: center;
    transition: all 0.5s;
    cursor: pointer;
  
    span {
		font-size: 20px;
		color: white;
		cursor: pointer;
		display: inline-block;
		position: relative;
		transition: 0.5s;
	
		&:after {
			content: '\00bb';
			position: absolute;
			opacity: 0;
			top: 0;
			right: -20px;
			transition: 0.5s;
		}
    }
  
    &:hover span {
		padding-right: 25px;
	
		&:after {
			opacity: 1;
			right: 0;
		}
	}
}

  .btn-cart {
    width: 100%;
    padding: 10px 16px;
    border-radius: 4px;
    box-shadow: -1px 2px 5px 0px #00000061;
    background-color: #1B66ED;
    position: relative;
    cursor: pointer;
    text-align: center;
    transition: all 0.5s;
    cursor: pointer;
  
    span {
      font-size: 22px;
      color: white;
      cursor: pointer;
      display: inline-block;
      position: relative;
      text-shadow: 1px 3px 2px black;
      transition: 0.5s;
      
  
      &:after {
        content: '\00bb';
        position: absolute;
        opacity: 0;
        top: 0;
        right: -20px;
        transition: 0.5s;
      }
    }
  
    &:hover span {
      padding-right: 25px;
  
      &:after {
        opacity: 1;
        right: 0;
      }
    }
  }

.btn-small {
	width: 30% !important;
}

.btn-transparent{
	background: rgba(0, 0, 0, 0.2);
	padding: 10px;
	border-radius: 20px;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	-ms-border-radius: 20px;
	-o-border-radius: 20px;
}

.btn-gray{
	background: #dbc4f3;
	color: $white;
	padding: 10px;
	margin: 12px 0px 12px 0px;
	-moz-border-radius: 20px;
	-ms-border-radius: 20px;
	-o-border-radius: 20px;
}


.btn-facebook {
	background-color: #3B5998;
	color: white;
	width: 80%;
    padding: 10px 16px;
    border-radius: 4px;
	box-shadow: -1px 2px 5px 0px #00000061;
	cursor: pointer;

	span {
		font-size: 20px;
		color: white;
		cursor: pointer;
		display: inline-block;
		position: relative;
		transition: 0.5s;
	
		&:after {
			content: '\00bb';
			position: absolute;
			opacity: 0;
			top: 0;
			right: -20px;
			transition: 0.5s;
		}
	}

	&:hover span {
		padding-right: 25px;
	
		&:after {
			opacity: 1;
			right: 0;
		}
	}
}


@media screen and (max-width: 768px) { 
	.btn-blue {
		width: 100%;
		margin-bottom: 32px;
	}

	.btn-orange-plus {
		margin-bottom: 16px;
	}
}


  