.side-bar {
    background: #3358f4;
    background: -webkit-linear-gradient(0deg,#3358f4,#1d8cf8);
    background: -webkit-gradient(linear,left bottom,left top,from(#3358f4),to(#1d8cf8));
    background: -webkit-linear-gradient(bottom,#3358f4,#1d8cf8);
    background: linear-gradient(0deg,#3358f4,#1d8cf8);
    width: 275px;
    height: 100vh;
    box-shadow: -4px 4px 18px 0px #000000;
    padding: 16px 0;
    position: sticky;
    top: 0;
    transition: all 300ms ease-in-out;

    .close-btn {
        position: absolute;
        right: 8px;
        top: 8px;
        padding: 8px;
        font-size: 1.6rem;
        display: none;
    }

    .profile-row {
        padding: 10px 0 25px 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.719);

        .profile-data {
            min-width: 55%;
            p {
                font-size: .75rem;
                color: $white;

                &:first-child {
                    font-size: .9rem;
                }
            }
        }

        .profile-img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            margin-right: 10px;
            object-fit: cover;
            object-position: center;
			box-shadow: -1px 1px 6px #0000008c;
			background-color: #fff;
        }
        
    }

    .nav-item {
        transition: all 300ms ease-in-out;
        cursor: pointer;

        a {
			padding: 16px 8px 16px 16px;
			&.active {
				border-left: 7px solid $white;
				background-color: #016EDE;
			}
        }

        i, img {
            font-size: 1.4rem;
            margin-right: 14px;
        }
        span {
            font-size: $fontRegular;
            letter-spacing: 1.3pt;
        }

        &:hover {
            border-left: 7px solid $white;
            background-color: #016EDE;
        }
    }
}

.btn-column {
	position: fixed;
    .btn-sidebar {
        position: absolute;
        font-size: 2rem;
        padding: 10px;
        top: 10px;
        display: none;
    } 
}

.open {
    left: 0px !important;
}

@media screen and (max-width: 1024px) { 
    .btn-column {
        .btn-sidebar {
			display: flex;
		}
    }
    
    .side-bar {
        width: 250px;
        position: fixed;
        height: 100vh;
        left: -250px;
        z-index: 1;

        .close-btn {
            display: flex;
        }
    }
}