.card-login {
    background: #3358f4;
    background: -webkit-linear-gradient(0deg,#3358f4,#1d8cf8);
    background: -webkit-gradient(linear,left bottom,left top,from(#3358f4),to(#1d8cf8));
    background: -webkit-linear-gradient(bottom,#3358f4,#1d8cf8);
    background: linear-gradient(0deg,#3358f4,#1d8cf8);
    width: 55%;
    height: 65%;
    min-height: 485px;
    border-radius: 10px;
    box-shadow: 1px 13px 20px 4px black;
    padding: 16px;
}

.card {
    padding: 16px ;
    background-color: #333a54;
    color: white;
    border-radius: 4px;
    box-shadow: 0px 3px 11px #000000e3;
}

.card-product {
    position: relative;
    width: 100%;

    .card-img-top {
        border-radius: 4px;
        box-shadow: 0px 6px 5px 2px black;
        height: 300px;
        width: 100%;
    }

    .product-detail {
        position: absolute;
        height: 55px;
        left: 6px;
        right: 6px;
        bottom: 11px;
        overflow: hidden;
        padding: 16px 4px 10px;
        background-color: #4f47eacc;
        transition: all 0.35s;
        text-align: center;
        box-shadow: 1px 2px 3px #1f1f1fd4;

        p {
            color: $white;
        }
      
        h5 {
            text-transform: capitalize;
            font-size: 17px;
            font-weight: 600;
            color: $white;
            line-height: 19px;
            margin: 3px 0;
            text-shadow: 1px 2px 2px #141414d6;
        }

  
      .subheading {
            font-weight: 500;
            margin: 0;
            color: $white;
            font-size: 14px;
            text-transform: capitalize;
            letter-spacing: 1px;
            text-align: center;
        }
  
        blockquote {
            padding: 24px 8px;
            color: $white;
            margin: 0;
            font-size: 1em;
        }
    }
  
    &:hover .product-detail {
      height: calc(94%);
      padding-top: 50px;
      background-color: rgba(79, 71, 234, 0.938);

        blockquote {
            padding: 16px 8px 24px;
        }
    }

    .btn-outline {
        border: 2px solid white;
        background: transparent;
        color: white;
        font-weight: 600;
        padding: 8px 10px;
        border-radius: 4px;
        margin-right: 8px;
        transition: all 300ms ease-in-out;
        cursor: pointer;
    
        &:hover {
          background-color: white;
          color: #4F47EA;
        }
    }

    .btn-white {
        background-color: $white;
        padding: 10px;
        border-radius: 4px;
        transition: all 300ms ease-in-out;
        cursor: pointer;

        i {
            color: #FF9607;
            font-size: 1.2rem;
        }

        &:hover {
            background-color: #FF9607;
            i {
                color: $white;
            }
        }
    }

    .ribbon-wrapper-red {
        width: 120px;
        height: 95px;
        overflow: hidden;
        position: absolute;
        top: -4px;
        right: -4px;
    }
    
    .ribbon-red {
        font: bold 15px Sans-Serif;
        color: #fff;
        text-align: center;
        text-shadow: rgba(255,255,255,0.5) 0px 1px 0px;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        position: relative;
        padding: 5px 33px;
        left: 22px;
        top: 20px;
        width: 127px;
        height: 29px;
        background-color: #ffcb43;
        background-image: linear-gradient(319deg, #ffcb43 0%, #ff6425 37%, #ff0016 100%);  
        color: #fff;
        -webkit-box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
        -moz-box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
        box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
    }
    
    .ribbon-red:before,
    .ribbon-red:after{
        content: "";
        border-top: 3px solid #b90005;   
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        position:absolute;
        bottom: -3px;
    }
    
    .ribbon-red:before{
        left: 0;
    }
    
    .ribbon-red:after{
        right: 0;
    }

  }