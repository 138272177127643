/**
*   colors.scss
*   @description: Variables de colores
*/

$primary:           #1176bc;
$secondary:         #059ed8;
$success:           #03a74e;
$info:              #17a2b8;
$warning:           #ffc107;
$danger:            #ea4235;

$black:             #000000;
$white:             #FFFFFF;
$gray:              #868686;
$darkgray:          #4e4e4e;

/* Classes de colores */

.color-white { color: $white; }

.color-black { color: $black; }

.color-gray { color: $gray; }

.color-darkgray { color: $darkgray; }

.color-primary { color: $primary; }

.color-secondary { color: $secondary; }

.color-success { color: $success; }

.color-info { color: $info; }

.color-warning { color: $warning; }

.color-danger { color: $danger; }
