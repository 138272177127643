$theme: rgb(253, 2, 2);

.upload {
	input[type="file"] {
		display: none;
	}

	.uploader {
		display: block;
		clear: both;
		margin: 0 auto;
		width: 100%;
		max-width: 600px;
	
		label {
			cursor: pointer;
			float: left;
			clear: both;
			width: 100%;
			padding: 2rem 1.5rem;
			text-align: center;
			background: #fff;
			border-radius: 7px;
			border: 3px solid #eee;
			transition: all .2s ease;
			user-select: none;
		}
		&:hover {
			border-color: #eee;
		}
		&.hover {
			border: 3px solid #eee;
			box-shadow: inset 0 0 0 6px #eee;
			
			#start {
				i.fa {
					transform: scale(0.8);
					opacity: 0.3;
				}
			}
		}
	}

	#start {
		float: left;
		clear: both;
		width: 100%;
		&.hidden {
			display: none;
		}
		i.fa {
			font-size: 50px;
			margin-bottom: 1rem;
			transition: all .2s ease-in-out;
		}
	}

	#response {
		float: left;
		clear: both;
		width: 100%;
		&.hidden {
			display: none;
		}
		#messages {
			margin-bottom: .5rem;
		}
	}

	#file-image {
		display: inline;
		margin: 0 auto .5rem auto;
		max-width: 180px;
		&.hidden {
			display: none;
		}
	}

	#notimage {
		display: block;
		float: left;
		clear: both;
		width: 100%;
		&.hidden {
		  display: none;
		}
	}
	
	progress, .progress {
		// appearance: none;
		display: inline;
		clear: both;
		margin: 0 auto;
		width: 100%;
		max-width: 180px;
		height: 8px;
		border: 0;
		border-radius: 4px;
		background-color: #eee;
		overflow: hidden;
	}

	.progress[value]::-webkit-progress-bar {
		border-radius: 4px;
		background-color: #eee;
	}
	
	.progress[value]::-webkit-progress-value {
		background: linear-gradient(to right, darken($theme,8%) 0%, $theme 50%);
		border-radius: 4px; 
	}

	.progress[value]::-moz-progress-bar {
		background: linear-gradient(to right, darken($theme,8%) 0%, $theme 50%);
		border-radius: 4px; 
	}
}