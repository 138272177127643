.profile-photo {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	img {
		width: 250px;
		height: 250px;
	}
}