.view {
    background-color: #1C1D2F;
    width: 100%;
    height: 100%;
    min-height: 100vh;

    .header-options {
        padding: 24px 0;
        .title {
            h1 {
                letter-spacing: 1.3pt;
            }
        }
    }

    ::-webkit-input-placeholder { /* Edge */
        color: rgba(255, 255, 255, 0.705);
    }
      
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: rgba(255, 255, 255, 0.705);
    }
      
    ::placeholder {
        color: rgba(255, 255, 255, 0.705);
    }
}

[data-title]:hover:after {
    opacity: 1;
    transition: all 0.05s ease 0.05s;
    visibility: visible;
}

[data-title]:after {
    content: attr(data-title);
    background-color: #333;
    color: #fff;
    font-size: 14px;
    font-family: Raleway;
    position: absolute;
    padding: 3px 20px;
	bottom: -1.6em;
	right: 0px;
    white-space: nowrap;
    box-shadow: 1px 1px 3px #222222;
    opacity: 0;
    border: 1px solid #111111;
    z-index: 99999;
    visibility: hidden;
    border-radius: 6px;
}

[data-title] {
    position: relative;
}

@media screen and (max-width: 1024px) {  
.view {
    .header-options { 
        .title h1 {
            letter-spacing: 1.3pt;
            padding-left: 32px;
        }
    }

    .column-margin {
        margin-top: 32px;
    }
    .input-search {
        position: relative;
        margin-top: 8px;

        .icon-search {
            top: 5px;
        }
    }
}
}