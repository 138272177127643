.input-search {
    position: relative;
    input {
        width: 100%;
        background-color: #ffffff6b;
        font-size: 14px;
        border-radius: 10px;
        margin-left: 10px;
        padding: 5px 10px;
        color: #ffffff;
    }

    .icon-search {
        position: absolute;
		right: 0px;
		top: 0px;
        border-radius: 0 10px 10px 0px !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
        transition: all 300ms ease-in-out;
        height: 100%;
		cursor: pointer;

        &:hover {
			background-color: #2262F5;
			color: #ffffff;
        }
    }
}
