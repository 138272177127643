.perfil-form {
    .table-form-content {
        padding: 16px 10px;
        background-color: #333a54;
        color: white;
        border-radius: 4px;
        box-shadow: 0px 3px 11px #000000e3;
        height: 300px;
        overflow-y: hidden;
        width: 100%;

        table {
            width: 100%;
            height: 280px;
            color: $white;
            display: flex;
            justify-content: space-between;
            overflow: auto;
            position: relative;

            thead {
                width: 100%;
                margin-top: -1px;
                position: absolute;
                background-color: #333a54;
            }

            tbody {
                margin-top: 40px;
                width: 100%;
                overflow-y: auto;
            }

            tr {
                display: flex;
                width: 100%;
                background-color: #333a54;
                transition: background-color 300ms ease-in-out;
                
                &:first-child {
                    background-color: #333a54;
                    &:hover {
                        background-color: #333a54;
                    }
                }
        
                &:hover {
                    background-color: #4f5a81;
                }
            }

            th {
                width: auto;
                text-align: center;
                padding: 10px 4px;
                color: $white;
                font-family: $Raleway;
                letter-spacing: .5pt;
                font-size: $fontTiny;
                text-transform: capitalize;
                border-bottom: 2px solid #0071FF;
            }

            td {
                padding: 10px 4px;
                color: $white;
            }
        }
    }
}