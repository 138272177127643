.btn-group {
    text-align: center;
    width: 40%;
    border: 1.5px solid #FF9607;
    height: 25px;
    border-radius: 11px;
    
    p {
        font-size: .8rem;
        color: $white;
        font-weight: 500;
    }
}

.btn-group > div + div {
    border-radius: 0px 6px 6px 0px;
    
}

.btn-group > div {
    cursor: pointer;
    height: 22px;
	background:#F2F2F2;
	border-radius: 6px 0px 0px 6px;
	font-size:0.8125em;
	color:#B9BCBC;
	text-decoration:none;
    width: 50%;
    text-transform:capitalize;
    transition: all 200ms ease-in-out;
}

.btn-group > div:nth-of-type(1) {
    background:transparent;
	color:#FFF;
}

.btn-group > div:nth-of-type(1):hover {
	background:rgb(255, 162, 33);
}

.btn-group > div:nth-of-type(2) {
	background:transparent;
	color:#FFF;
}

.btn-group > div:nth-of-type(2):hover {
	background:#FF9607;
}

.btn-group-active {
    background:#FF9607 !important;
	color:#FFF;
}