.cursos {
	.events-wrapper {
        background: rgba(80, 38, 221, 1);
        position: static !important;
        color: #ffff;

	    .events {
		
		}

		.mockup-side {
			position: relative;
			.mockup {
				position: absolute;
				transform: rotateY(-20deg) rotateX(20deg);
				transition: all 2s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
				background: rgba(176, 141, 203,0.2); //rgba(23, 12, 61, 0.2);
				perspective-origin: left bottom;
				transform-style: flat;
				border-radius: 10px;
				position: absolute;
				backdrop-filter: blur(10px);
				box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 0.5px inset;
				&:hover {
					transform: rotateY(0deg) rotateX(0deg);
				}
			}

			#mc1 {
				top: -30px;
				left: 160px;
			}
			#mc2 {
				top: -30px;
				left: -35px;
			}
			#mc3 {
				top: 0px;
				left: -14px;
				width: 701px;
				height: 428px;
			}
			#mc4 {
				top: 140px;
				left: -80px;
			}
			#mc5 {
				top: 180px;
				left: 240px;
				width: 414px;
				height: 273px;
			}
		}
	}

	.section-2 {
        background: #8867ac;//#1f1f47;
        
        .row-cursos{
            width: 95%;
            margin: 10px;
        }
        .title{
            font-size: 30px;
            font-weight: bold;
            color: #ffffff;
        }

		.subtitle{
            font-size: 25px;
			color: #ffffff;
		}
		
		

        .subtitle-line{
            font-size: 23px;
			color: #ffffff;
			border-top: solid 2px;
			margin-top: 10px;
			padding-top: 10px;
		}
		.subtitle-line:active{
			font-size: bold;
			color: #ffffff;
		}

		.card-list {
			padding: 20px;
			height: 400px;
			background: rgba(15, 14, 71, 0.3);
			box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 0.5px inset;
			border-radius: 20px;

			.grid-list {
				display: grid;
				gap: 8px;
				width: 287px;
				height: 100%;
				margin-top: 20px;
				overflow: hidden;
				-webkit-mask-image: linear-gradient(rgb(255, 255, 255) 80%, rgba(255, 255, 255, 0) 100%);
			}
		}

		.card-img {
			height: 360px;
			border-radius: 20px;

			p {
				line-height: 1.5em;
			}
		}

		.card-section2 {
			height: 200px;
			border-radius: 20px;

			p {
				line-height: 1.5em;
			}
		}

		.resultado-etapa1 {
			
		}
	}

	.section-3 {
		background: #8867ac;//#1f1f47;
        
        .row-cursos{
            width: 95%;
            margin: 10px;
        }
        .title{
            font-size: 30px;
            font-weight: bold;
            color: #ffffff;
        }

        .subtitle{
            font-size: 25px;
            color: #ffffff;
        }

		.card-list {
			padding: 20px;
			height: 400px;
			background: rgba(15, 14, 71, 0.3);
			box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 0.5px inset;
			border-radius: 20px;

			.grid-list {
				display: grid;
				gap: 8px;
				width: 287px;
				height: 100%;
				margin-top: 20px;
				overflow: hidden;
				-webkit-mask-image: linear-gradient(rgb(255, 255, 255) 80%, rgba(255, 255, 255, 0) 100%);
			}
		}

		.card-img {
			height: 360px;
			border-radius: 20px;

			p {
				line-height: 1.5em;
			}
		}

		.card-section3 {
			height: 200px;
			border-radius: 20px;

			p {
				line-height: 1.5em;
			}
		}
	}

	.bg-g1 {
		background: #dbc4f3;
	}

	.bg-g2 {
		background: #dbc4f3;
	}
	.bg-g3 {
		background: #dbc4f3;
	}
	.bg-g4 {
		background: #dbc4f3;
		margin-bottom: 10px;
	}
	.bg-g5 {
		background: #dbc4f3;
	}
}

@media screen and (min-width: 1440px) {
}

@media screen and (max-width: 992px) {
	.index {
	}
}

@media screen and (max-width: 768px) {
	.index {
	}
}
