.login-view {
  height: 100vh;
  .logo-column {
  background-image: url(/images/background.jpg);
  background-size: 100%;
  background-position-y: center;
  background-position-x: center;
  background-repeat: no-repeat;
  }
  .overlay-login {
      height: 100%;
      width: 100%;
      background-color: #000b43b6;
  }

  .form-column {
      background-color: #11141f;
      height: 100%;
      width: 100%;

      h1 {
          font-size: 2.4rem;
          text-shadow: 1px 7px 5px #000000c7;
      }

      .label-form{
          width: 80%;
          padding: $paddingMedium 0;
      }
  }

  ::-webkit-input-placeholder { /* Edge */
      color: rgba(255, 255, 255, 0.705);
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: rgba(255, 255, 255, 0.705);
    }
    
    ::placeholder {
      color: rgba(255, 255, 255, 0.705);
    }
}

@media screen and (max-width: 1024px) {
  .login-view {

    .logo-column {
      background-size: cover; 
    }

    .form-column {
      background-color: #11141f00;
      height: 100%;
      position: absolute;
      width: 100%;

      .card-login {
        width: 70%;
      }
    }
  }
}


@media screen and (max-width: 768px) {
  .login-view {

   
    .form-column {
      .btn-facebook {
        padding: 10px 8px; 
        span {
          font-size: .9rem; 
        }
      }

      .btn-login {
        width: 80%;
        padding: 10px 8px;

        span {
          font-size: .9rem; 
        }
      }

      h1 {
        font-size: 2rem;
      }

      .card-login {
        width: 70%;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .login-view {
    .form-column {
      
      h1 {
        font-size: 1.6rem;
      }

      .card-login {
        width: 80%;
      }
    }
  }
}