header {
	padding: $paddingNormal 0;
	transition: background-color 0.4s ease-out;
	width: 100%;
	height: 130px;
	img {
		height: 60px;
	}

	.logo {
		width: 25%;
	}

	.nav-icon {
		display: none;
	}

	.nav-item {
		padding: $paddingSmall $paddingNormal;
		border: 1px solid transparent;

		a {
			color: rgba(0, 0, 0, 0.4);
			transition: color 300ms ease-in-out;
			font-size: $fontSmall;
			&:hover {
				color: rgba(0, 0, 0, 0.7);
			}
		}

		i {
			padding-right: 4px;
		}

		&:hover {
            background-color: rgba(#fbfbfb, 0.2);
            border: 1px solid rgba(0, 0, 0, 0.7);
			border-radius: 10px;
			transition: background-color 0.4s ease-out;
			-webkit-transition: background-color 0.4s ease-out;
			-moz-transition: background-color 0.4s ease-out;
			-ms-transition: background-color 0.4s ease-out;
			-o-transition: background-color 0.4s ease-out;
		}
	}
}

header.scroll {
	background-color: var(--black);
	color: #eef;
}

// @media screen and (max-width: 992px) {
// 	header {
// 		padding-bottom: 4px;
// 		.nav-icon {
// 			display: block;
// 			margin: 1em;
// 			width: 35px;
// 			position: absolute;
// 			right: 5px;
// 			bottom: 2px;

// 			z-index: 2;

// 			&:after,
// 			&:before,
// 			div {
// 				background-color: #fff;
// 				border-radius: 3px;
// 				content: "";
// 				display: block;
// 				height: 4px;
// 				margin: 5px 0;
// 				transition: all 0.3s ease-in-out;
// 			}

// 			&:hover {
// 				&:after,
// 				&:before,
// 				div {
// 					background-color: var(--red);
// 				}
// 			}
// 		}

// 		.nav-icon-active {
// 			&:before {
// 				transform: translateY(10px) rotate(135deg);
// 			}
// 			&:after {
// 				transform: translateY(-9px) rotate(-135deg);
// 			}
// 			div {
// 				transform: scale(0);
// 			}
// 		}

// 		img {
// 			height: 50px;
// 			margin-bottom: 6px;
// 		}

// 		.logo {
// 			width: 100%;
// 		}

// 		.main {
// 			flex-direction: column;
// 			padding: 64px 24px;
// 			top: 0;
// 			right: 0;
// 			width: 35%;
// 			z-index: 1;
// 			height: 100vh;
// 			position: fixed;
// 			background-color: var(--black);
// 			transform: translateX(100%);
// 			transition: all 300ms ease-in-out;

// 			.nav-item {
// 				margin-bottom: 24px;
// 			}
// 		}

// 		.main-appear {
// 			transform: translateX(0);
// 		}

// 		.main-hide {
// 			transform: translateX(100%);
// 		}
// 	}
// }

// @media screen and (max-width: 768px) {
// 	header {
// 		.main {
// 			width: 60%;
// 		}
// 	}
// }
