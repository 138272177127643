.paginator {
    .page {
        padding: 4px 13px;
        background-color: #1581F7;
        color: white;
        cursor: pointer;
        font-size: $fontSmall;
        font-weight: $weightBold;
        border-radius: 4px;
        margin: 4px;
        transition: background-color 300ms;

        &:hover {
            background-color: #2D5BF4;
        }
    }

    .active {
        background-color: #2D5BF4;
    }
}