footer{
    background-color: #b18ecb;
    padding: 30px;

    .logo{
        img {
            height: 60px;
        }
    }


    .footer-item{
        padding-bottom: 5px;
    }

}