.input {
    width: 100%;
    font-size: 14px;
    border-radius: 4px;
    background: $white;
    outline: none;
    padding: 6px 10px;
    color: black;
    -webkit-box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.139);
    box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.139);
}

.input-modals {
	background: #ffffff3d;
	color: white;
	font-size: 1.2rem;
	height: 31px;
}

.input-modals-textarea {
	background: #ffffff3d;
	color: white;
	font-size: 1.2rem;
}

.input-number {
    width: 40%;
    font-size: 15px;
    border-radius: 4px;
    background: #ffffff8f;
    outline: none;
    padding: 6px 10px;
    font-weight: bold;
    color: $white;
    -webkit-box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.139);
    box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.139);
}

.input-form-login {
    width: 80%;
    font-size: 14px;
    border: 1.4px solid $white;
    border-radius: 4px;
    background: transparent;
    outline: none;
    padding: 10px;
    color: $white;
    -webkit-box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.139);
    box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.139);
}

.input-file-img {
    padding: 5px 10px;
    text-align: center;
    border-radius: 4px;
    transition: all 300ms ease-in-out;
    cursor: pointer;
    
    &:hover {
        background-color: white;
        color:  #2b3246;
    }
}

.inputfile {
    /* visibility: hidden etc. wont work */
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile:focus + label {
	/* keyboard navigation */
	outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
	pointer-events: none;
}

.text-modal{
    background: #ffffff3d;
	color: white;
	font-size: 1.2rem;
}

option {
	font-weight:normal;
	color: #ff2f2f !important;
}

textarea {
	resize: none;
}
