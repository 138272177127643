.landing {
	.section-1 {
		height: 100vh;
		background: var(--background); //rgba(80, 38, 221, 1);
		background-image: url(../../images/wave.svg);
		background-repeat: no-repeat;
		background-size: cover;

		.message-side {
			.message-s1 {
				line-height: 1.5em;
			}

			.card-price {
				background: rgba(15, 14, 71, 0.3);
				padding: $paddingMedium 0px;
				border-radius: 12px;
				-webkit-border-radius: 12px;
				-moz-border-radius: 12px;
				-ms-border-radius: 12px;
				-o-border-radius: 12px;
				transition: all 2s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;

				.round {
					background-color: #b08dcbff;
					width: 70px;
					height: 70px;
					border-radius: 50px;
					-webkit-border-radius: 50px;
					-moz-border-radius: 50px;
					-ms-border-radius: 50px;
					-o-border-radius: 50px;
				}

				&:hover {
					background: rgba(15, 14, 71, 0.35);
					box-shadow: rgba(105, 100, 100, 0.288) 7px 5px 10px 0.5px;
				}
			}
		}

		.mockup-side {
			position: relative;
			.mockup {
				position: absolute;
				transform: rotateY(-20deg) rotateX(20deg);
				transition: all 2s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
				background: rgba(176, 141, 203, 0.2); //rgba(23, 12, 61, 0.2);
				perspective-origin: left bottom;
				transform-style: flat;
				border-radius: 10px;
				position: absolute;
				backdrop-filter: blur(10px);
				box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 0.5px inset;
				&:hover {
					transform: rotateY(0deg) rotateX(0deg);
				}
			}

			#mc1 {
				top: -30px;
				left: 160px;
			}
			#mc2 {
				top: -30px;
				left: -35px;
			}
			#mc3 {
				top: 0px;
				left: -14px;
				width: 701px;
				height: 428px;
			}
			#mc4 {
				top: 140px;
				left: -80px;
			}
			#mc5 {
				top: 180px;
				left: 240px;
				width: 414px;
				height: 273px;
			}
		}
	}

	.section-2 {
		background: rgb(255,255,255);
		background: linear-gradient(49deg, rgba(255,255,255,0) 42%, rgba(176,141,203,0.4682247899159664) 100%);
		.card-list {
			padding: 20px;
			height: 400px;
			background: rgba(15, 14, 71, 0.3);
			box-shadow: rgba(105, 100, 100, 0.548) 0px 0px 0px 0.5px;
			border-radius: 20px;

			.grid-list {
				display: grid;
				gap: 8px;
				width: 287px;
				height: 100%;
				margin-top: 20px;
				overflow: hidden;
				-webkit-mask-image: linear-gradient(rgb(255, 255, 255) 80%, rgba(255, 255, 255, 0) 100%);
			}
		}

		.card-img {
			height: 360px;
			border-radius: 20px;
			width: 250px;

			p {
				line-height: 1.5em;
			}
		}
	}

	.section-3 {
		background: var(--background);
	}

	.footer{
		background-color: #b08dcbff;
		height: 100vh;
	}

	.bg-g1 {
		background: linear-gradient(209.21deg, rgb(159, 127, 229) 13.57%, rgb(78, 153, 227) 98.38%);
	}

	.bg-g2 {
		background: linear-gradient(209.21deg, rgb(47, 184, 255) 13.57%, rgb(158, 236, 217) 98.38%);
	}
	.bg-g3 {
		background: linear-gradient(209.21deg, rgb(75, 190, 255) 13.57%, rgb(33, 107, 218) 98.38%);
	}
	.bg-g4 {
		background: linear-gradient(209.21deg, rgb(238, 184, 109) 13.57%, rgb(153, 70, 178) 98.38%);
	}
	.bg-g5 {
		background: linear-gradient(209.21deg, rgb(100, 66, 137) 13.57%, rgb(129, 122, 227) 98.38%);
	}
}

@media screen and (min-width: 1440px) {
}

@media screen and (max-width: 992px) {
	.index {
	}
}

@media screen and (max-width: 768px) {
	.index {
	}
}
