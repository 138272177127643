.dashboard {
	.maestro-card {
		border-radius: 8px;
		background-color: #333a54;
		padding: 10px;
		margin-top: 20px;

		.profile-image {
			min-height: 150px;
			padding-right: 10px;
		}

		b, p, h2 {
			color: whitesmoke;
		}

		.profile-image {

			@media screen and (max-width: 1024px) {
				width: 100%;
				justify-content: center;
				align-items: center;
			}

			img {
				width: 200px;
				height: 100%;
				border-radius: 8px;
			}
		}
	}
}