.table-container {
    padding: 16px 10px;
    background-color: #333a54;
    color: white;
    border-radius: 4px;
    box-shadow: 0px 3px 11px #000000e3;
    min-height: 510px;

    .icon-title {
        padding-right: 8px;
    }

    thead {
		width: 100%;
        i {
            font-size: $fontMini;
            padding: 7px 5px 5px;
            background: #ffffff61;
            border-radius: 4px;
            margin-right: 10px;
        }

        span {
            font-size: $fontRegular;
            font-weight: 500;
            letter-spacing: .5pt;
        }
    }

	tbody {
		width: 100%;
	}

    th,td {
        text-align: left;
        padding: 10px 4px;
        color: $white;
        font-family: $Raleway;
        letter-spacing: .5pt;
        font-size: $fontTiny;
        border-bottom: 2px solid #0071FF;
    }

    tr {
		width: 100%;
        background-color: #333a54;
		transition: background-color 300ms ease-in-out;
    }

	th {
		width: auto;
		text-align: center;
		padding: 10px 4px;
		color: $white;
		font-family: $Raleway;
		letter-spacing: .5pt;
		font-size: $fontTiny;
		text-transform: capitalize;
		border-bottom: 2px solid #0071FF;
	}

	td {
		width: auto;
		padding: 10px 4px;
		color: $white;
	}
}

table {
	width: 100%;
    .text-center {
        text-align: center;
    }
    .text-left {
        text-align: left;
    }
    .text-right {
        text-align: right;
    }
}

.table-import {
    padding: 16px 10px;
    background-color: #333a54 !important;
    color: white;
    border-radius: 4px;
    height: auto !important;
    padding: 12px;
    max-height: none;
    box-shadow: none;
}

.pointer {
    cursor: pointer;
}