.react-calendar{
    background-color: #ffffff3d !important;


    .react-calendar__navigation__label__labelText {
        color: white;
        font-weight: 600;
    }

    .react-calendar__month-view__days__day{
        color: white;
        
    }

    .react-calendar__tile--now {
        color: black;
    }

    .react-calendar__month-view__days{
        color: white;
        :hover{
            color: black;
        }
    }

    
}